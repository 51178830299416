import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
} from "@fortawesome/free-solid-svg-icons"

export const LivraisonDates = props => {
  const optionsDate = { weekday: "long", month: "short", day: "numeric" }
  
  let libelleDate = "Passez commande avant "
  let livraisonDate = props.currentLivraison && props.currentLivraison.livr_end_saisie
  let livraisonDateLivr = props.currentLivraison && props.currentLivraison.livr_date  

  if (props.currentLivraison && (parseInt(props.currentLivraison.livr_status) > 5 || new Date() > new Date(props.currentLivraison.livr_end_saisie + " 23:59:59"))) {
    libelleDate = "Saisie des commandes estimée à partir du "
    const numJours = parseInt(props.dataZone.zone_ouverturecmd)

    if (props.currentLivraison.dateNextEnd !== undefined && props.currentLivraison.dateNextEnd !== null) {
      var dateProLivr = new Date(props.currentLivraison.dateNextEnd)
      dateProLivr.setDate(dateProLivr.getDate() - numJours)
      livraisonDate = dateProLivr
      let dateLivrPro = new Date(props.currentLivraison.dateNextLivr)
      dateLivrPro.setDate(dateLivrPro.getDate())
      livraisonDateLivr = dateLivrPro
    } else {
      const numWeeks = parseInt(props.dataZone.zone_periodicite)
      let dateEndLivr = new Date(props.currentLivraison.livr_end_saisie)
      dateEndLivr.setDate(dateEndLivr.getDate() + (numWeeks * 7) - numJours)
      livraisonDate = dateEndLivr
      let dateLivr = new Date(props.currentLivraison.livr_date)
      dateLivr.setDate(dateLivr.getDate() + (numWeeks * 7))
      livraisonDateLivr = dateLivr
    }
  }

  const GetTrueDateLivraison = (date) => {
    if (props.dataGroup) {
      var weekday = []
      weekday[1] = "lundi"
      weekday[2] = "mardi"
      weekday[3] = "mercredi"
      weekday[4] = "jeudi"
      weekday[5] = "vendredi"
      weekday[6] = "samedi"
      weekday[7] = "dimanche"

      const livraisonStringFindID = weekday.indexOf(new Intl.DateTimeFormat("fr-FR", { weekday: "long" }).format(new Date(date)))
      let dateLivrPro = new Date(date)
      dateLivrPro.setDate(dateLivrPro.getDate() - (livraisonStringFindID - props.dataGroup.node.group_daylivr))
      return dateLivrPro
    } else {
      return date
    }
  }

  return (
    <>
      {props.currentLivraison && parseInt(props.currentLivraison.livr_status) === 5 && new Date() < new Date(props.currentLivraison.livr_end_saisie + " 23:59:59") ?
        <>
          {props.isMultiple ? <div style={{fontWeight: "bold", color: "#4d9f16"}}>Livraison {props.currentLivraison.livr_type_name}&nbsp;:</div> : null}
          <div className="has-text-weight-bold">
            <FontAwesomeIcon icon={faClock} className="mr-2 is-size-6" />{" "}
            {props.currentLivraison && parseInt(props.currentLivraison.livr_status) >= 5
              ? "Passez commande avant "
              : "Passez commande à partir du "}
            <span style={{ color: "#c93828" }}>
              {props.currentLivraison && props.currentLivraison.livr_end_saisie ? new Intl.DateTimeFormat("fr-FR", optionsDate).format(new Date(props.currentLivraison.livr_end_saisie)) : ""}
              {props.currentLivraison && parseInt(props.currentLivraison.livr_status) >= 5
                ? " minuit"
                : ""}
            </span>
            {props.currentLivraison
              ? <span style={{ display: "none" }}>{props.currentLivraison.livr_id}</span>
              : ""}
          </div>
          <div className="has-text-weight-bold">
            <FontAwesomeIcon icon={faClock} className="mr-2 is-size-6" />{" "}
            {props.currentLivraison && parseInt(props.currentLivraison.livr_status) === 5
              ? "Livraison "
              : "Livré "}
            <span style={{ color: "#c93828" }}>
              {props.currentLivraison && props.currentLivraison.livr_date ? new Intl.DateTimeFormat("fr-FR", optionsDate).format(new Date(GetTrueDateLivraison(props.currentLivraison.livr_date))) : ""}
            </span>
          </div>
        </>
        : props.currentLivraison && props.currentLivraison.livr_type_name === "normale" ?
          <>
            <div className="has-text-weight-bold">
              <FontAwesomeIcon icon={faClock} className="mr-2 is-size-6" />{" "}
              {libelleDate}
              <span style={{ color: "#c93828" }}>
                {props.currentLivraison && props.currentLivraison.livr_end_saisie ? new Intl.DateTimeFormat("fr-FR", optionsDate).format(new Date(livraisonDate)) : ""}
              </span>
            </div>
            <div className="has-text-weight-bold">
              <FontAwesomeIcon icon={faClock} className="mr-2 is-size-6" />{" "}
            Prochaine livraison estimée le {" "}
              <span style={{ color: "#c93828" }}>
                {props.currentLivraison && props.currentLivraison.livr_date ? new Intl.DateTimeFormat("fr-FR", optionsDate).format(new Date(GetTrueDateLivraison(livraisonDateLivr))) : ""}
              </span>
            </div>
          </>
          : ""
      }
    </>
  )
}

export default LivraisonDates
