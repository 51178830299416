import React, { useState } from "react"
import { Link } from "gatsby"
import uuid from "uuid/v4"
import { useSelector, useDispatch } from "react-redux"
import deepEqual from "deep-equal"
import slugify from 'react-slugify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTrash,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { addProduct, updateProduct, removeProduct } from "../actions/panier"
import {
  addProductAPI,
  updProductAPI,
  delProductAPI,
  setProductSortAPI,
} from "../actions/panierAPI"
import groceriesIcon from "../images/icon/paper-bag.svg"

const Product = ({prod, setProduct, setDisplayAlert, currentLivraisons, zone_id}) => {
  const dispatch = useDispatch()
  const panier = useSelector(state => state.panierR)
  const identifiants = useSelector(s => s.identifiants)
  const loading = useSelector(state => state.loadingR)

  const GetValueInPanier = (product_id, product_livr_id) => {
    const produitPanier = panier.products.find(p => p.product_id === product_id && p.product_livr_id === product_livr_id)
    return (produitPanier && String(produitPanier.qty).replace('.00', '').replace(',00', '')) || ''
  }

  const GetConvertionCalculatrice = (product_id, product_livr_id, product_convert, product_convertpu) => {
    let coeff = parseFloat(product_convertpu)
    let unite = "unités"
    let arrondi = 0
    if (parseFloat(product_convert) !== 1) {
      coeff = parseFloat(product_convert)
      unite = "kg"
      arrondi = 2
    }
    return (parseFloat(GetValueInPanier(product_id, product_livr_id)).toFixed(2) * parseFloat(coeff)).toFixed(arrondi) + " " + unite
  }

  const CheckDisableProduct = livr_id => {
    if (!currentLivraisons) return true;
    const productLivr = parseInt(livr_id);
    const livrData = currentLivraisons.find(thisLivrData => parseInt(thisLivrData.livr_id) === productLivr);
    if (!livrData) return true;
    return parseInt(livrData.livr_status) !== 5 || new Date() > new Date(livrData.livr_end_saisie + ' 23:59:59');
  }
  
  const handleSave = (event, qty_available) => {
    event.preventDefault();
    var boolAlert = true;
    var dataset = event.target.dataset;

    if (panier.products.length > 0) {
      if (panier.products.find(product => {
        if(product.product_livr_id === dataset.product_livr_id || !loading || !loading.livraisons) return false;

        const productLivrData = loading.livraisons.find(thisLivr => thisLivr.livr_id === product.product_livr_id);
        const datasetLivrData = loading.livraisons.find(thisLivr => thisLivr.livr_id === dataset.product_livr_id);

        return !productLivrData || !datasetLivrData || productLivrData.livr_zone_id !== datasetLivrData.livr_zone_id;
      })) {
        alert("Votre panier contient des articles d'un autre point de dépôt. Videz votre panier d'abord si vous souhaitez commander dans ce dépôt pour cette livraison.")
        return;
      }
    }

    const product = {
      product_id: dataset.product_id,
      product_name: dataset.product_name,
      product_price: dataset.product_price,
      product_livr_id: dataset.product_livr_id,
      product_cat: dataset.product_cat,
      price_unit: dataset.price_unit,
      product_min_value: dataset.min,
      product_max_value: dataset.max,
      qty: event.target.value,
      product_entier: dataset.product_entier,
    }

    // On compare sur tout sauf l'uuid
    const sameProduct = panier.products.find(e =>
      deepEqual(product, {
        product_id: e.product_id,
        product_name: e.product_name,
        product_price: e.product_price,
        product_livr_id: e.product_livr_id,
        product_cat: e.product_cat,
        price_unit: e.price_unit,
        product_min_value: e.product_min_value,
        product_max_value: e.product_max_value,
        qty: product.qty,
        product_entier: e.product_entier,
      })
    )

    if (qty_available !== null && qty_available !== undefined && parseFloat(event.target.value) > parseFloat(qty_available)) {
      event.target.value = Math.round(qty_available);
      product.qty = event.target.value;
    }

    if (typeof sameProduct === "undefined") {
      if (product.qty !== '') {
        dispatch(addProduct({ ...product, _id: uuid(), zone: zone_id }))
        if (identifiants.identifiants !== null) {
          dispatch(
            addProductAPI({
              ...product,
              group: panier.pickupPoint,
              person: identifiants.identifiants.customer_id,
              zone: zone_id,
            }, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
          )
        }
      } else {
        boolAlert = false
      }
    } else {
      if (product.qty !== '') {
        dispatch(
          updateProduct({
            _id: sameProduct._id,
            qty: event.target.value,
          })
        )
        if (identifiants.identifiants !== null) {
          dispatch(
            updProductAPI({
              ...product,
              group: panier.pickupPoint,
              person: identifiants.identifiants.customer_id,
            }, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
          )
        }
      } else {
        if (identifiants.identifiants !== null) {
          dispatch(
            delProductAPI({
              ...product,
              group: panier.pickupPoint,
              person: identifiants.identifiants.customer_id,
            }, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
          )
        } else {
          dispatch(removeProduct(sameProduct._id))
        }
        boolAlert = false
      }
    }

    if (boolAlert) {
      setProduct(product)
      setDisplayAlert(true)
      setInterval(() => {
        setDisplayAlert(false)
      }, 6000)
    }
  }

  const handleRemove = (id_product, event) => {
    event.preventDefault()

    if (identifiants.identifiants !== null) {
      const product = panier.products.find(u => parseInt(u.product_id) === parseInt(id_product))
      dispatch(
        delProductAPI({
          ...product,
          person: identifiants.identifiants.customer_id
        },identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
      )
    } else {
      if (panier && panier.products && panier.products.length > 0) {
        if (panier.products.find(product => parseInt(product.product_id) === parseInt(id_product))._id) {
          dispatch(removeProduct(panier.products.find(product => parseInt(product.product_id) === parseInt(id_product))._id))
        }
      }
    }

    const inputField = document.querySelector(`#quantite-${id_product}`);
      
    if (inputField) {
      inputField.value = '';
    }
  }

  return (
    <div className="column is-4 pt-2 pb-2 is-half-tablet" key={prod.product_id}>
      <div
        className={
          "box" +
          (GetValueInPanier(
            prod.product_id,
            prod.product_livr_id
          )
            ? " is-active"
            : "")
        }
      >
        <div className="columns is-vcentered">
          {prod.product_img ?
            <div className="column col-article-img is-narrow">
              <img
                src={`${process.env.GATSBY_BACKEND_URL}${prod.product_img}`}
                alt={prod.product_name}
              />
            </div> : null}
          <div
            className="column col-article-content pl-4"
            style={{
              borderLeft: prod.product_img ? "1px solid #ddd" : "",
            }}
          >
            {GetValueInPanier(
                prod.product_id,
                prod.product_livr_id
              ) ? <div className="ml-2 has-text-weight-bold is-size-7" style={{color: "#c93828", float: "right"}}>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(GetValueInPanier(
                prod.product_id, prod.product_livr_id) * prod.product_price)}
            </div> : null}
            <h3 className="has-text-weight-bold">
              {prod.product_name}{prod.product_new === "1" ? <span className="tag is-info ml-2">Nouveau</span> : null}
            </h3>
            {prod.product_description ? <div className="is-size-7 mt-2 product-description" dangerouslySetInnerHTML={{__html: prod.product_description.replace(/\\/g, '')}}></div> : null}
            {prod.product_qty_available !== null && prod.product_qty_available !== undefined ? <div className="mt-2"><span className="notification is-info" style={{padding: "0.2rem 0.5rem"}}>Quantité disponible : {Math.round(prod.product_qty_available)}</span></div> : null}
            <div className="field has-addons mt-3">
              <div className="control">
                <div className="button is-light">
                  {new Intl.NumberFormat(
                    "fr-FR",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  ).format(prod.product_price)}
                  {" / "}
                  {prod.price_unit}
                </div>
              </div>
              <div
                className={
                  "control " +
                  (!CheckDisableProduct(prod.product_livr_id)
                    ? "has-tooltip-hidden-tablet has-tooltip-hidden-touch"
                    : "has-tooltip-arrow has-tooltip-top has-tooltip-multiline")
                }
                data-tooltip={
                  CheckDisableProduct(prod.product_livr_id)
                    ? "La saisie des commandes n'est pas possible pour l'instant pour ce produit. Reportez-vous aux dates de prochaines livraisons pour ce dépôt." : ""
                }
              >
                <input
                  type="number"
                  style={{ width: "70px" }}
                  id={`quantite-${prod.product_id}`}
                  step={
                    parseInt(
                      prod.product_entier
                    ) === 1
                      ? 1
                      : 0.1
                  }
                  min={prod.product_min_value}
                  max={prod.product_qty_available !== null && prod.product_qty_available !== undefined ? Math.round(prod.product_qty_available) : prod.product_max_value}
                  className="input input-number"
                  defaultValue={GetValueInPanier(
                    prod.product_id,
                    prod.product_livr_id
                  )}
                  disabled={CheckDisableProduct(prod.product_livr_id)}
                  data-product_id={
                    prod.product_id
                  }
                  data-product_name={
                    prod.product_name
                  }
                  data-product_price={
                    prod.product_price
                  }
                  data-product_livr_id={
                    prod.product_livr_id
                  }
                  data-product_cat={
                    prod.product_cat
                  }
                  data-price_unit={
                    prod.price_unit
                  }
                  data-product_entier={
                    prod.product_entier
                  }
                  data-min={
                    prod.product_min_value
                  }
                  data-max={
                    prod.product_max_value
                  }
                  onChange={e => { if (parseFloat(e.target.value)) { handleSave(e, prod.product_qty_available) } } }
                  onBlur={e => { if (parseFloat(GetValueInPanier(prod.product_id, prod.product_livr_id)) && !parseFloat(e.target.value)) { handleRemove(prod.product_id, e) } } }
                />
              </div>

              {GetValueInPanier(
                prod.product_id,
                prod.product_livr_id
              ) ? (
                <div className="control delete-btn">
                  <button
                    className="button is-danger"
                    aria-label="Supprimer"
                    onClick={(event) => { 
                      const qtyElem = window.document.querySelector(`#quantite-${prod.product_id}`)
                      if (qtyElem && parseFloat(qtyElem.value)) { handleRemove(prod.product_id, event) }}
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="is-size-6"
                    />
                  </button>
                </div>
                ) : null}
            </div>
            {(parseFloat(prod.product_convert) !== 1 || parseFloat(prod.product_convertpu) !== 1) &&
              parseFloat(GetValueInPanier(prod.product_id, prod.product_livr_id)) > 0 ? (
                <div className="control">
                  {/* calculatrice */}
                  <div className="is-size-7">
                    Soit environ {" "}
                    {GetConvertionCalculatrice(prod.product_id, prod.product_livr_id, prod.product_convert, prod.product_convertpu)}{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Products = props => {
  const dispatch = useDispatch()
  const panier = useSelector(state => state.panierR)
  const identifiants = useSelector(s => s.identifiants)
  const [product, setProduct] = useState([]) /* produit en cours */
  const [displayAlert, setDisplayAlert] = useState(false)

  const indexThisCatContext = props.allCategories.findIndex(i => parseInt(i.cat_id) === parseInt(props.currentCateg)) + 1
  const catNext = props.allCategories[indexThisCatContext] ? props.allCategories[indexThisCatContext].cat_name : ""

  const handleDisplay = event => {
    event.preventDefault()
    setDisplayAlert(false)
  }

  const GetProducteurList = cat_id => {
    let output = []
    let uniqueArr = [...new Set(props.produits.filter(p => parseInt(p.product_cat) === parseInt(cat_id)).map(data => data.producer_id))]
    uniqueArr.map(data => output.push(props.produits.find(p => parseInt(p.producer_id) === parseInt(data))))
    return output
  }

  const setProductSort = sort => {
    dispatch(setProductSortAPI(sort, identifiants.identifiants.customer_id))
  }

  const isSortByProducer = (identifiants && identifiants.identifiants && parseInt(identifiants.identifiants.customer_product_sort_by_producer)) || 0;

  return (
    <>
      {!props.currentLivraisons ? (
        <div className="spinnerbody spinnerheigth">
          <svg
            className="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      ) : props.currentLivraisons.length < 1 ? (
        <p className="subtitle"><button aria-label="Chargement..." className="ml-3 button is-loading is-large"></button></p>
      ) : (
            <div className="column">
              <div className="container is-fluid container-family">
                <div
                  className={`notification ${displayAlert ? "" : "is-hidden"} alertProduct is-success is-light`}
                >
                  {" "}
                  {/* alert ajout panier */}
                  <button onClick={e => handleDisplay(e)} className="delete">
                    {" "}
                  </button>
                  {product.qty} {product.price_unit}{" "}
                  <strong>{product.product_name}</strong> ajouté au panier
                </div>
                {identifiants && identifiants.identifiants ? (
                  <div className="mt-3 mb-5 is-flex" style={{justifyContent: "center", alignItems: "center"}}>
                    <span className="mr-2">Tri&nbsp;:</span>
                    <div className="buttons has-addons">
                      <button className={`button is-small ${isSortByProducer ? '' : 'is-success'}`} onClick={() => setProductSort(0)}>Tous<span className="is-hidden-mobile">&nbsp;producteurs confondus</span></button>
                      <button className={`button is-small ${isSortByProducer ? 'is-success' : ''}`} onClick={() => setProductSort(1)}>Par producteur</button>
                    </div>
                  </div>
                ) : null}
                {props.allCategories === ""
                  ? ""
                  : props.allCategories
                    .filter(c => props.displaySearch || props.filteredCategories.includes(parseInt(c.cat_id)))
                    .map(cat => (
                      <div key={cat.cat_id}>
                        <h2 className="is-size-2 dreamland mt-4 mb-4 has-text-centered">
                          {cat.cat_img ? <img src={`${process.env.GATSBY_BACKEND_URL}${cat.cat_img}`} alt={cat.cat_name} /> : null}
                          {cat.cat_name}
                        </h2>
                        {props.produits !== "" ?
                          (
                            isSortByProducer ?
                            (
                              GetProducteurList(cat.cat_id).length > 0 ? 
                                GetProducteurList(cat.cat_id)
                                  .sort((a, b) => a.producer_name > b.producer_name)
                                  .map(({ key, ...producter }) => (
                                    <div key={producter.producer_id}>
                                      <div className="is-4 m-4">
                                        <span className="dreamland subtitle">{producter.producer_name + " - " + producter.producer_city}</span>
                                        {producter.producer_url !== "" ? (
                                          <a
                                            className="lienProducteur ml-3 is-size-7"
                                            style={{color: "#3a169f"}}
                                            rel="noreferrer"
                                            target="_blank"
                                            href={producter.producer_url}
                                          >
                                            Site web
                                          </a>
                                        ) : null}
                                      </div>
                                      <div className="family-grid columns is-multiline is-2 is-variable is-mobile">
                                        {props.produits
                                            .filter(p => parseInt(p.product_cat) === parseInt(cat.cat_id) && parseInt(p.product_producer_id) === parseInt(producter.producer_id))
                                            .map(prod => (
                                              <Product
                                                prod={prod}
                                                setProduct={setProduct}
                                                setDisplayAlert={setDisplayAlert}
                                                currentLivraisons={props.currentLivraisons}
                                                zone_id={props.zone_id}
                                                key={prod.product_id}
                                              />
                                            ))}
                                      </div>
                                    </div>
                                  )) : null
                            ) : (
                              <div className="family-grid columns is-multiline is-2 is-variable is-mobile">
                                {props.produits
                                  .filter(p => parseInt(p.product_cat) === parseInt(cat.cat_id))
                                  .map((prod) => (
                                    <Product
                                      prod={prod}
                                      setProduct={setProduct}
                                      setDisplayAlert={setDisplayAlert}
                                      currentLivraisons={props.currentLivraisons}
                                      zone_id={props.zone_id}
                                      key={`${prod.product_id}-${prod.product_livr_id}`}
                                    />
                                  ))}
                              </div>
                            )
                          ) : null}
                      </div>
                    ))}
                {catNext !== "" ?
                  <>
                    <div className={`has-text-right mb-5 mt-5 bottom-links ${!props.displaySearch ? "" : "is-hidden"}`}>
                      <Link to={`/producteur/${props.zone_id}-${props.zone_info}/produits/${slugify(`${catNext.replace("&", "").toLowerCase()}`)}`} className="dreamland is-size-3">
                        Categorie suivante ({catNext})
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ marginRight: "1rem" }}
                        />
                      </Link>
                    </div>
                  </> : ""}
                  {panier.products.length 
                  // && (!identifiants.identifiants || !identifiants.identifiants.currentCmdEndInput || 
                  //   !identifiants.identifiants.currentCmdEndInput.length )
                    
                     ? <div className="has-text-right mb-5 bottom-links">
                    <Link to="/panier" className="button" style={{background: "#ffff50", height: "auto"}}>
                      <img
                        src={groceriesIcon}
                        style={{ width: "2.5rem", marginBottom: 0 }}
                        alt="Panier"
                        className="mr-5"
                      />
                      <span className="dreamland is-size-3 mr-1">Voir mon panier</span>
                      <span className="mr-2 is-size-7" style={{background: "#d61d1d", borderRadius: "50%", color: "#fff", padding: "1px 0.4rem"}}>{panier && panier.products.length}</span>
                      <span>{new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(panier.products.length ? panier.products.reduce((acc, cur) => acc + (cur.product_price * cur.qty), 0) : 0)}</span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ marginLeft: "1rem" }}
                      />
                    </Link>
                  </div> : null}
              </div>
            </div>
          )}
    </>
  )
}

export default Products
