import React, { useState } from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import slugify from 'react-slugify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faSearch,
  faTimesCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"

import producteurIcon from "../images/icon/courrier-de-livraison.svg"
import barnIcon from "../images/icon/barn.svg"
import groceriesIcon from "../images/icon/paper-bag.svg"
import LivraisonDates from "./livraisonDates"

export const MenuProduct = props => {
  const data = useStaticQuery(graphql`
    query dataMenu {
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
            zone_libelle
            group_daylivr
          }
        }
      }
      allMysqlZone {
        nodes {
          zone_libelle
          zone_id
          zone_periodicite
          zone_ouverturecmd
        }
      }
    }
  `)
  const panier = useSelector(state => state.panierR)
  const [isFolded, setIsFolded] = useState(true)

  const dataGroup = data.allMysqlGroupe.edges.find(
    legroupe =>
      parseInt(legroupe.node.group_zone_id) === parseInt(props.zone_id) &&
      parseInt(legroupe.node.group_id) === parseInt(props.currentPointDepot)
  )
  const dataZone = data.allMysqlZone.nodes.find(lazone => parseInt(lazone.zone_id) === parseInt(props.zone_id))
  const libelleZone = dataZone.zone_libelle

  const handleResetSearch = () => {
    props.setSearch("")
  }

  const handleSearchProduct = evt => {
    props.setSearch(evt.target.value)
  }
  
  const handleSearchKey = evt => {
    if (evt.key === 'Enter') {
      setIsFolded(true);
    }
  }

  const GetValueInPanierByCat = (cat_id) => {
    var qtyretur = 0
    if (
      panier.products.filter(
        p =>
          parseInt(p.product_cat) === parseInt(cat_id)
      )[0] !== undefined
    ) {
      qtyretur = parseFloat(
        panier.products.filter(
          p =>
            parseInt(p.product_cat) === parseInt(cat_id)
        ).length
      )
    }
    return parseFloat(qtyretur)
  }

  return (
    <>
      <aside
        className={`column is-3 pattern-squares ${isFolded ? "is-folded" : "is-unfolded"}`}
        style={{ padding: 0, boxShadow: "0 0 5px #777" }}
      >
        <div className="menuCategory">
          <button onClick={() => setIsFolded(!isFolded)} className="is-hidden-tablet button btn-fold is-warning has-text-weight-bold is-size-2 almond fold-menu" title={`${isFolded ? "Déplier le menu" : "Replier le menu"}`} aria-label={`${isFolded ? "Déplier le menu" : "Replier le menu"}`}>
            {isFolded ? "»" : "«"}
          </button>
          <section className="pt-3 pb-4 pl-3 top-infos">
            {props.currentLivraisons.map(currentLivraison =>
              <LivraisonDates
                isMultiple={props.currentLivraisons.length > 1}
                currentLivraison={currentLivraison}
                dataZone={dataZone}
                dataGroup={dataGroup}
                zone_id={props.zone_id}
                key={currentLivraison.livr_id}
              />)}
            {dataGroup ? (
              <div className="mt-3">
                <img
                  src={barnIcon}
                  style={{
                    width: 30,
                    height: 30,
                    float: "left",
                    marginBottom: 0,
                  }}
                  alt="Point de dépôt"
                  className="img-depot"
                />
                <span className="ml-3" style={{ fontSize: 14 }}>
                  {dataGroup.node.group_person} -{" "}
                  {dataGroup.node.group_horaire}
                </span>
                <Link to="/#points-retrait" className="ml-3">
                  Modifier
            </Link>
              </div>
            ) : (
                ""
              )}
            <div className="mt-3" style={{ clear: "left" }}>
              <img
                src={producteurIcon}
                style={{
                  width: 30,
                  height: 30,
                  float: "left",
                  marginBottom: 0,
                }}
                alt="Producteur"
                className="img-prod"
              />
              <span className="ml-3" style={{ fontSize: 14 }}>{libelleZone}</span>{" "}
              <Link to="/contact" className="ml-3">
                Contacter
              </Link>
            </div>
          </section>
          {!props.currentLivraisons ? (
            <div className="spinnerbody spinnerheigth">
              <svg
                className="spinner"
                width="65px"
                height="65px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="path"
                  fill="none"
                  strokeWidth="6"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                ></circle>
              </svg>
            </div>
          ) : props.currentLivraisons.length < 1 ? (
            <p className="subtitle"><button aria-label="Chargement..." className="ml-5 button is-loading is-large"></button></p>
          ) : (<>
            <div className="search-field field has-addons pr-3 ml-3" style={{ display: "flex", justifyContent: "center" }}>
              <div className="control has-icons-left">
                <input value={props.search} type="text" id="searchProduct" name="searchProduct" onChange={e => handleSearchProduct(e)} onKeyDown={e => handleSearchKey(e)} className="input" placeholder="Rechercher un produit" />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <div className={`control is-hidden-tablet ${props.search ? "" : "is-hidden"}`}>
                <button className="button" onClick={() => setIsFolded(true)} title="Afficher les résultats" aria-label="Afficher les résultats">
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </div>
              <div className={`control ${props.search ? "" : "is-hidden"}`}>
                <button className="button" onClick={e => handleResetSearch()} title="Effacer la recherche" aria-label="Effacer la recherche">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </div>
            </div>
            <Link to="/panier" className="panier-side">
              <div className={`${isFolded ? "is-hidden-tablet" : "is-hidden"}`}>
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(panier.products.length ? panier.products.reduce((acc, cur) => acc + (cur.product_price * cur.qty), 0) : 0)}
              </div>
              <div className="columns is-vcentered is-mobile">
                <div className="column is-narrow has-text-centered p-1 pl-4">
                  <img
                    src={groceriesIcon}
                    style={{ width: "2.5rem", marginBottom: 0 }}
                    alt="Panier"
                    className="mr-5 ml-5"
                  />
                </div>
                <div className="column p-1">
                  <span className="label-panier dreamland is-size-3">Panier</span>
                  <span className="ml-2 is-size-7" style={{ position: "relative", top: -5, background: "#d61d1d", borderRadius: "50%", color: "#fff", padding: "1px 0.4rem" }}>{panier && panier.products.length}</span>
                  {panier.products.length === 0 ? "" :
                    <>
                      <span className="ml-3 has-text-weight-bold" style={{ position: "relative", top: -4 }}>{new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(panier.products.reduce((acc, cur) => acc + (cur.product_price * cur.qty), 0))}
                      </span>
                    </>}
                </div>
                <div className="column is-narrow is-size-4 chevron">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginRight: "1rem" }}
                  />
                </div>
              </div>
            </Link>
            <nav className="menu mt-4">
              <ul className="menu-list">
                {props.allCategories
                  ? props.allCategories.sort((a, b) => parseInt(a.cat_order) > parseInt(b.cat_order)).map((cat, id) => (

                    <li
                      key={id + cat.cat_name}
                      className={parseInt(cat.cat_id) === props.currentCateg ? "current" : ""}
                    >
                      <Link
                        to={`/producteur/${props.zone_id}-${props.zone_info}/produits/${slugify(`${cat.cat_name.replace("&", "").toLowerCase()}`)}`}
                        onClick={(e) => { if (parseInt(cat.cat_id) === props.currentCateg) { e.preventDefault(); setIsFolded(true); } }}
                        aria-label={cat.cat_name}
                        title={cat.cat_name}
                        role="button"
                      >
                        {cat.cat_img ? <img src={`${process.env.GATSBY_BACKEND_URL}${cat.cat_img}`} alt={cat.cat_name} /> : null}
                        <span>{cat.cat_name}</span>
                        {GetValueInPanierByCat(cat.cat_id) > 0 ? (
                          <span className="counter">{GetValueInPanierByCat(cat.cat_id)}</span>
                        ) : null}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ float: "right" }}
                          className="chevron"
                        />
                      </Link>
                    </li>
                  )) : ""}
              </ul>
            </nav>
          </>)}
        </div>
      </aside>
    </>
  )
}

export default MenuProduct
